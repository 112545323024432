"use client";

import type { Dispatch, ReactNode } from "react";
import { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";

interface EditUiType {
  active: boolean; // true : the interface is edited by a pro, false : the website is displayed to the public
  textEditable: boolean;
  selectedBlockId: string | null;
  webAdminForm: string | null;
}

const EditUiContext = createContext<EditUiType>({
  active: true,
  textEditable: false,
  selectedBlockId: null,
  webAdminForm: null,
});

const EditUiDispatchContext = createContext<Dispatch<EditUiActionType> | null>(null);

interface EditUiProviderProps {
  active: boolean;
  textEditable: boolean;
  children: ReactNode;
}

export function EditUiProvider({ active, textEditable, children }: EditUiProviderProps): ReactNode {
  const [editUi, dispatch] = useImmerReducer(editUiReducer, {
    active,
    textEditable: active ? textEditable : false,
    selectedBlockId: null,
    webAdminForm: null,
  });

  return (
    <EditUiContext.Provider value={editUi}>
      <EditUiDispatchContext.Provider value={dispatch}>{children}</EditUiDispatchContext.Provider>
    </EditUiContext.Provider>
  );
}

export function useEditUiContext() {
  return useContext(EditUiContext);
}

export function useEditUiDispatch() {
  const context = useContext(EditUiDispatchContext);
  if (!context) throw new Error("useEditUiDispatch must be used within a EditUiProvider");
  return context;
}

interface GlobalActivateActionType {
  type: "on" | "off";
}
interface ParameterActionType {
  type: "changeParameter";
  parameter: "webAdminForm";
  value: string | null;
}
type EditUiActionType = GlobalActivateActionType | ParameterActionType;

function editUiReducer(state: EditUiType, action: EditUiActionType) {
  switch (action.type) {
    case "on":
      state.textEditable = true;
      state.active = true;
      break;
    case "off":
      state.textEditable = false;
      state.active = false;
      break;
    case "changeParameter":
      state[action.parameter] = action.value;
      break;
  }
}
