import {
  Dosis,
  Handlee,
  Indie_Flower,
  Inter,
  Itim,
  Merienda,
  Oooh_Baby,
  Poiret_One,
  Prata,
  Quattrocento,
  Satisfy,
} from "next/font/google";
import { TemplateType } from "./theme-type";

export const allLightColors = [
  "bg-orange-500",
  "bg-lime-500",
  "bg-rose-500",
  "bg-sky-500",
  "bg-purple-500",
  "bg-amber-500",
  "bg-red-500",
  "bg-teal-500",
  "bg-green-500",
  "bg-yellow-500",
  "bg-indigo-500",
  "bg-fushia-500",
];

export const allDarkColors = [
  "bg-orange-900",
  "bg-lime-900",
  "bg-rose-900",
  "bg-sky-900",
  "bg-red-900",
  "bg-purple-900",
  "bg-amber-900",
  "bg-teal-900",
  "bg-green-900",
  "bg-yellow-900",
  "bg-indigo-900",
  "bg-fushia-900",
];
const merienda = Merienda({ subsets: ["latin"], variable: "--font-merianda" });
const prata = Prata({ subsets: ["latin"], variable: "--font-prata", weight: "400" });
const inter = Inter({ subsets: ["latin"] });
const dosis = Dosis({ subsets: ["latin"] });
const indieFlower = Indie_Flower({ subsets: ["latin"], weight: "400" });
const itim = Itim({ subsets: ["latin"], weight: "400" });
const handlee = Handlee({ subsets: ["latin"], weight: "400" });
const ooohBaby = Oooh_Baby({ subsets: ["latin"], weight: "400" });
const poiretOne = Poiret_One({ subsets: ["latin"], weight: "400" });
const quattrocento = Quattrocento({ subsets: ["latin"], weight: "400" });
const satisfy = Satisfy({ subsets: ["latin"], weight: "400" });

export const allFonts = [
  { label: "Dosis", value: "dosis" },
  { label: "Merienda", value: "merienda" },
  { label: "Handlee", value: "handlee" },
  { label: "Indie Flower", value: "indieFlower" },
  { label: "Inter", value: "inter" },
  { label: "Itim", value: "itim" },
  { label: "Oooh Baby", value: "ooohBaby" },
  { label: "Poiret One", value: "poiretOne" },
  { label: "Prata", value: "prata" },
  { label: "Quattrocento", value: "quattrocento" },
  { label: "Satisfy", value: "satisfy" },
];
export const fonts = {
  merienda: { className: merienda.className, sizeClassName: "" },
  prata: { className: prata.className, sizeClassName: "" },
  inter: { className: inter.className, sizeClassName: "" },
  itim: { className: itim.className, sizeClassName: "" },
  dosis: { className: dosis.className, sizeClassName: "" },
  indieFlower: { className: indieFlower.className, sizeClassName: "font-size-sm" },
  handlee: { className: handlee.className, sizeClassName: "" },
  ooohBaby: { className: ooohBaby.className, sizeClassName: "font-size-sm" },
  poiretOne: { className: poiretOne.className, sizeClassName: "" },
  quattrocento: { className: quattrocento.className, sizeClassName: "" },
  satisfy: { className: satisfy.className, sizeClassName: "" },
};

export const templates: TemplateType[] = [
  {
    id: "1",
    label: "Ciel d'azur",
    colorCode: "theme-blue",
    font: "merienda",
    menuOptions: ["slider"],
    headerOptions: [""],
    modes: [],
  },
  {
    id: "53",
    label: "Banquise",
    colorCode: "theme-wb",
    font: "ooohBaby",
    menuOptions: ["", "delarte"],
    headerOptions: [],
    modes: ["light"],
  },
  {
    id: "5",
    label: "Ardoise",
    colorCode: "theme-wb",
    font: "handlee",
    menuOptions: ["density", "delarte"],
    headerOptions: [],
    modes: ["dark"],
  },

  {
    id: "4",
    label: "Rubis",
    colorCode: "theme-red",
    font: "indieFlower",
    menuOptions: [],
    headerOptions: [],
    modes: ["dark", "radius"],
  },
  {
    id: "102",
    label: "Ciel d'azur dense",
    colorCode: "theme-green",
    font: "merienda",
    menuOptions: ["slider", "density"],
    headerOptions: [""],
    modes: [],
  },
  {
    id: "2",
    label: "Couleurs alternées",
    colorCode: "theme-wb",
    font: "indieFlower",
    menuOptions: ["multicolor", "slider"],
    headerOptions: ["transparency", "backgroundImages"],
    modes: ["radius", "dark"],
  },

  {
    id: "52",
    label: "Blanc classique",
    colorCode: "theme-wb",
    font: "satisfy",
    menuOptions: ["delarte"],
    headerOptions: [],
    modes: ["light"],
  },

  {
    id: "3",
    label: "Vert sombre",
    colorCode: "theme-green",
    font: "poiretOne",
    menuOptions: [""],
    headerOptions: ["backgroundImages"],
    modes: ["dark", "radius"],
  },
  {
    id: "6",
    label: "Multicolor",
    colorCode: "theme-wb",
    font: "dosis",
    menuOptions: ["multicolor"],
    headerOptions: [],
    modes: ["radius"],
  },
  {
    id: "7",
    label: "Multicolor 2",
    colorCode: "theme-wb",
    font: "quattrocento",
    menuOptions: ["multicolor"],
    headerOptions: [],
    modes: ["radius"],
  },
];

export const allColors = [
  { code: "theme-multicolor", color: "", label: "Multicolor" },
  { code: "theme-wb", color: "#18181b", label: "Noir et blanc" },
  { code: "theme-red", color: "#eb3333", label: "Rouge" },
  { code: "theme-green", color: "#338e5c", label: "Vert" },
  { code: "theme-blue", color: "#00416A", label: "Bleu" },
  { code: "theme-yellow", color: "#facc15", label: "Jaune" },
  { code: "theme-pink", color: "#ff6e7f", label: "Rose" },
];

export const allThemeModes = [
  { label: "Mode sombre", code: "dark" },
  { label: "Coins arrondis", code: "radius" },
];
export const allThemeMenuOptions = [
  {
    label: "Défilement horizontal",
    code: "slider",
    description: "Afficher les éléments horizontalement",
  },
  {
    label: "Affichage dense",
    code: "density",
    description: "Afficher plus d'éléments par ligne",
  },
  {
    label: "Images sans marges ",
    code: "full",
    description: "Les images feront toute la largeur",
  },
  {
    label: "Example : Images non détourées",
    code: "delarte",
    description: "Utilise des images rectangulaires comme exemple",
  },
  // { label: "Textes animés", code: "textAnimation", description: "Animer le texte des éléments" },
];
