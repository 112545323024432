"use client";

import type { Dispatch, ReactElement, ReactNode } from "react";
import { Children, cloneElement, createContext, isValidElement, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { fonts, templates } from "./theme-data";
import { ThemeAreaType } from "./theme-type";

const ThemesContext = createContext<ThemeAreaType[]>([]);

const ThemesDispatchContext = createContext<Dispatch<ThemeActionType> | null>(null);

interface ThemesProviderProps {
  initialThemeAreas: ThemeAreaType[];
  children: ReactNode;
}

export function ThemesProvider({ initialThemeAreas, children }: ThemesProviderProps): ReactNode {
  const [themes, dispatch] = useImmerReducer(themesReducer, initialThemeAreas);
  return (
    <ThemesContext.Provider value={themes}>
      <ThemesDispatchContext.Provider value={dispatch}>{children}</ThemesDispatchContext.Provider>
    </ThemesContext.Provider>
  );
}

export function useThemeArea(areaCode: string) {
  const themeAreas = useContext(ThemesContext);
  if (!themeAreas) throw new Error("useThemeArea must be used within a ThemesProvider");
  const area = themeAreas.find((ta) => ta.code === areaCode);
  if (!area) throw new Error(`No theme area with code ${areaCode}`);
  return area;
}

export function useThemesDispatch() {
  const context = useContext(ThemesDispatchContext);
  if (!context) throw new Error("useThemesDispatch must be used within a ThemesProvider");
  return context;
}

export interface ThemeColorActionType {
  themeAreaCode: string;
  colorCode: string;
  type: "colorChange";
}
export interface ThemeFontActionType {
  themeAreaCode: string;
  font: string;
  type: "fontChange";
}
export interface ThemeModeActionType {
  themeAreaCode: string;
  mode: string;
  type: "modeOn" | "modeOff";
}
export interface ThemeMenuOptionActionType {
  themeAreaCode: string;
  menuOption: string;
  type: "menuOptionOn" | "menuOptionOff";
}
export interface ThemeTemplateActionType {
  themeAreaCode: string;
  templateId: string;
  type: "templateChange";
}
type ThemeActionType =
  | ThemeColorActionType
  | ThemeModeActionType
  | ThemeMenuOptionActionType
  | ThemeTemplateActionType
  | ThemeFontActionType;

function themesReducer(themeAreas: ThemeAreaType[], action: ThemeActionType) {
  const area = themeAreas.find((ta) => ta.code === action.themeAreaCode);
  if (!area) return themeAreas;

  switch (action.type) {
    case "modeOn": {
      if (!area.theme.modes.includes(action.mode)) area.theme.modes.push(action.mode);
      break;
    }
    case "modeOff": {
      if (area.theme.modes.includes(action.mode))
        area.theme.modes = area.theme.modes.filter((m) => m !== action.mode);
      break;
    }
    case "fontChange": {
      area.theme.font = action.font;
      break;
    }
    case "colorChange": {
      area.theme.colorCode = action.colorCode;
      if (action.colorCode === "theme-multicolor") area.theme.menuOptions.push("multicolor");
      else area.theme.menuOptions = area.theme.menuOptions.filter((m) => m !== "multicolor");
      break;
    }
    case "menuOptionOn": {
      if (!area.theme.menuOptions || !area.theme.menuOptions.includes(action.menuOption)) {
        if (!area.theme.menuOptions) area.theme.menuOptions = [];
        area.theme.menuOptions.push(action.menuOption);
      }
      break;
    }
    case "menuOptionOff": {
      if (area.theme.menuOptions && area.theme.menuOptions.includes(action.menuOption))
        area.theme.menuOptions = area.theme.menuOptions.filter((m) => m !== action.menuOption);
      break;
    }
    case "templateChange": {
      const template = templates.find((t) => t.id === action.templateId);
      if (template) {
        const { colorCode, font, menuOptions, modes, headerOptions } = template;
        area.theme = {
          ...area.theme,
          templateId: template.id,
          colorCode,
          font,
          menuOptions,
          modes,
          headerOptions,
        };
      }
      break;
    }
  }
}
interface ChildrenProps {
  className: string;
  style?: React.CSSProperties;
}
export function ThemesConsumer({
  areaCode,
  children,
}: {
  areaCode: string;
  children: ReactNode;
  adjustFontSize?: boolean;
}): ReactNode {
  const themeAreas = useContext(ThemesContext);
  const area = themeAreas.find((ta) => ta.code === areaCode);
  if (!area) return;
  const themeModeLabels = area.theme.modes.join(" ");
  // if (adjustFontSize && document)
  //   document.getElementsByTagName("html")[0].style.fontSize = fonts[area.theme.font].adjustSize;
  // Directly apply class on child tag (to manange body)
  return Children.map<ReactNode, ReactNode>(children, (child) => {
    if (isValidElement(child)) {
      const typedChild = child as ReactElement<ChildrenProps>;
      return cloneElement(typedChild, {
        className: `${typedChild.props.className ? typedChild.props.className : ""} ${area.theme.colorCode} 
        ${themeModeLabels} ${fonts[area.theme.font].className} ${fonts[area.theme.font].sizeClassName}`,
      });
    }
  });
}
